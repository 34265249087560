<template>
  <page>
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <bb-icon
            icon="ic-subscriptions"
            color="#6E19F0"
            :size="32"
          />
        </template>
        Subscriptions & stores
      </page-title>
    </page-section>
    <page-section bottom-border>
      <div v-if="loading">
        <bb-loader />
      </div>
      <div v-else>
        <div class="hidden xl:flex bg-bb-light-grey">
          <div class="value value-store p1 font-black">Store</div>
          <div class="value value-subscription p1 font-black">Subscription</div>
          <div class="value value-status p1 font-black">Status</div>
        </div>
        <ol class="grid gap-4 sm:grid-cols-2 xl:block">
          <li
            v-for="row in subscriptionRows"
            :key="row.store.id"
            class="row-card"
          >
            <div class="value value-store truncate">
              {{ row.store.name }}
            </div>

            <div class="value value-subscription">
              <subscription-badge :slug="row.store.subscription_slug" />
            </div>

            <div class="value value-status">
              <subscription-status :store="row.store" />
            </div>
          </li>
        </ol>
      </div>
    </page-section>

    <page-section>
      <div class="flex justify-center items-center">
        <the-button
          @click="connectStore"
          secondary
        >
          <template #default>
            <span>Add another store</span>
          </template>
          <template #icon>
            <ic-plus :size="16" />
          </template>
        </the-button>
      </div>
    </page-section>
  </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IcCog from 'vue-material-design-icons/Cog'
import IcSubscriptions from '@/components/icon/ic-subscriptions'
import ConnectStoreModal from '@/components/modals/ConnectStoreModal'
import SubscriptionBadge from '@/components/subscriptions/SubscriptionBadge'
import BbLoader from '@/components/loader/BBLoader'
import TransparentModal from '@/components/modals/base/TransparentModal'
import SubscriptionSelector from '@/components/subscriptions/SubscriptionSelector'
import SubscriptionSelectionModal from '@/components/modals/SubscriptionSelectionModal'
import SubscriptionStatus from '@/components/subscriptions/SubscriptionStatus'
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'

export default {
  name: 'subscriptions',
  components: {
    PageSection,
    Page,
    SubscriptionStatus,
    SubscriptionSelector,
    TransparentModal,
    BbLoader,
    SubscriptionBadge,
    IcSubscriptions,
    IcCog,
  },
  data() {
    return {
      loaded: false,
      editRow: null,
    }
  },
  computed: {
    ...mapGetters({
      stores: 'store/stores',
      loadingStores: 'store/isFetchingStores',
      loadingSubscriptions: 'subscription/isSubscriptionsLoading',
      getSubscriptionBySlug: 'subscription/getSubscriptionBySlug',
    }),
    loading() {
      return this.loadingStores || this.loadingSubscriptions || !this.loaded
    },
    subscriptionRows() {
      return this.stores.map(store => {
        const subscription = this.getSubscriptionBySlug(store.subscription_slug)

        return {
          store: store,
          subscription: subscription.name,
          // pricing:      subscription.pricing.readable,
        }
      })
    },
  },
  methods: {
    ...mapActions({
      loadSubscriptionsIfNeeded: 'subscription/loadSubscriptionsIfNeeded',
    }),
    connectStore() {
      this.$modal.show(ConnectStoreModal, null, ConnectStoreModal.modalProps)
    },
  },
  beforeMount() {
    this.loadSubscriptionsIfNeeded().then(() => (this.loaded = true))
  },
}
</script>

<style lang="scss" scoped>
.value {
  @screen xl {
    @apply flex py-4 px-2;
  }
}

.row-card {
  border: $bb-pale-grey 1px solid;
  border-radius: 8px;
  padding: 16px;
  display: grid;
  grid-template-areas:
    'store store subsc'
    'statu statu statu';

  @apply shadow-md;

  @screen xl {
    @apply shadow-none;
    display: flex;
    border-width: 0;
    padding: 0;
    border-bottom-width: 1px;

    &:last-child {
      border-bottom-width: 0;
    }
  }
}

.value-store {
  grid-area: store;
  font-weight: 900;

  @screen xl {
    width: 25%;

    font-weight: 500;
    &.p1 {
      font-weight: 900;
    }
  }
}

.value-subscription {
  grid-area: subsc;
  display: flex;
  justify-content: flex-end;

  @screen xl {
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    margin-bottom: 0;
    &.p1 {
      align-items: flex-start;
    }
  }
}

//.value-pricing {
//    grid-area: prici;
//
//    @screen xl {
//        width: 25%;
//    }
//}
//
//.value-status {
//    grid-area: statu;
//
//    @screen xl {
//        width: 30%;
//    }
//}
</style>
