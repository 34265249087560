<template>
  <div
    class="modal-backdrop"
    @click="close"
  >
    <div class="modal max-w-md">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TransparentModal',
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  backdrop-filter: blur(8px);
  background: rgba(61, 58, 92, 0.2);
}

.modal {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}
</style>
